<template>
  <div class="Product">
    <div class="back">
      <div class="back_ground"></div>
      <img class="iphone" src="../assets/product/iphone.png" alt="" />
      <div class="back_right">
        <div class="download">
          <img src="@/assets/product/android.png" alt="" />
          <div>立即下载</div>
        </div>
        <div class="QRcode">
          <QrCode></QrCode>
        </div>
        <div class="QRcode_text">扫码下载</div>
      </div>
    </div>
    <div class="Product_body">
      <div class="member">
        <div class="member_name">蜗牛地图会员体系</div>
        <div class="member_content">
          <div class="general">
            <div class="general_name">普通会员</div>
            <div class="general_money">¥0/m</div>
            <div class="general_text">
              会员权限介绍会员权限介绍 会员权限介绍
            </div>
            <a-button class="general_reserve">预定</a-button>
            <img
              class="general_card"
              src="../assets/product/general_card.png"
            />
            <img
              class="general_themes"
              src="../assets/product/Themes.png"
              alt=""
            />
            <ul>
              <li><a-icon type="check" />10 功能免费权限</li>
              <li>
                <a-icon type="check" />详细功能介绍详细功能介绍详细功
                能介绍详细详细功能介绍
              </li>
              <li><a-icon type="check" />详细功能介绍详细功能介绍</li>
              <li><a-icon type="check" />详细功能介绍</li>
              <li><a-icon type="check" />详细功能介绍详细功能</li>
              <li class="color"><a-icon type="check" />详细功能介绍</li>
              <li class="color"><a-icon type="check" />详细功能</li>
              <li class="color">
                <a-icon type="check" />详细功能介绍详细功能介绍
              </li>
            </ul>
          </div>
          <div class="general">
            <div class="vip_name">vip会员</div>
            <div class="general_money">¥8/m</div>
            <div class="general_text">
              会员权限介绍会员权限介绍 会员权限介绍
            </div>
            <a-button class="general_reserve">预定</a-button>
            <img class="vip_card" src="../assets/product/vip_card.png" alt="" />
            <img class="vip_themes" src="../assets/product/vip_themes.png" />
            <ul>
              <li><a-icon type="check" />10 功能免费权限</li>
              <li>
                <a-icon type="check" />详细功能介绍详细功能介绍详细功
                能介绍详细详细功能介绍
              </li>
              <li><a-icon type="check" />详细功能介绍详细功能介绍</li>
              <li><a-icon type="check" />详细功能介绍</li>
              <li><a-icon type="check" />详细功能介绍详细功能</li>
              <li><a-icon type="check" />详细功能介绍</li>
              <li class="color"><a-icon type="check" />详细功能</li>
              <li class="color">
                <a-icon type="check" />详细功能介绍详细功能介绍
              </li>
            </ul>
          </div>
          <div class="general">
            <div class="enterprise_name">企业会员</div>
            <div class="general_money">¥18/m</div>
            <div class="general_text">
              会员权限介绍会员权限介绍 会员权限介绍
            </div>
            <a-button class="general_reserve">预定</a-button>
            <img
              class="enterprise_card"
              src="../assets/product/enterprise_card.png"
              alt=""
            />
            <img
              class="enterprise_themes"
              src="../assets/product/enterprise_themes.png"
              alt=""
            />
            <ul>
              <li><a-icon type="check" />10 功能免费权限</li>
              <li>
                <a-icon type="check" />详细功能介绍详细功能介绍详细功
                能介绍详细详细功能介绍
              </li>
              <li><a-icon type="check" />详细功能介绍详细功能介绍</li>
              <li><a-icon type="check" />详细功能介绍</li>
              <li><a-icon type="check" />详细功能介绍详细功能</li>
              <li><a-icon type="check" />详细功能介绍</li>
              <li><a-icon type="check" />详细功能</li>
              <li><a-icon type="check" />详细功能介绍详细功能介绍</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="sdk">
        <div class="sdk_title">定制SDK</div>
        <div class="sdk_content">
          <div class="sdk_text">SDK功能介绍</div>
          <div class="sdk_text">SDK功能介绍SDK功能介绍SDK功能介绍</div>
          <div class="sdk_text">SDK功能介绍</div>
        </div>
        <a-button class="sdk_down"
          >SDK下载<a-icon type="arrow-right"
        /></a-button>
      </div>
    </div>
  </div>
</template>

<script>
import QrCode from "../components/qrCode.vue";
export default { components: { QrCode } };
</script>

<style lang="scss" scoped>
.Product {
  width: 100%;
  .back {
    height: calc(98vw * 0.5625);
    min-height: 576px;
    background: url("../assets/product/bag.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10% 0;
    position: relative;
    .back_ground {
      width: 50%;
      height: 55%;
      position: absolute;
      top: 22%;
      left: 30%;
      z-index: 1;
      background: linear-gradient(
        38deg,
        #648bd7 0%,
        rgba(100, 139, 215, 0) 100%
      );
      box-shadow: 0px 15px 50px 1px rgba(0, 0, 0, 0.06);
      border-radius: 20px;
    }
    .iphone {
      width: 20%;
      margin-right: 10%;
      z-index: 2;
    }
    .back_right {
      width: 20%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      z-index: 2;
      .download {
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #333333;
        color: #fff;
        border-radius: 10px;
        padding: 10px;
        font-size: 14px;
        line-height: 14px;
        img {
          margin: 5px 0;
          width: 10%;
          margin-right: 10%;
        }
      }
      .QRcode {
        display: flex;
        margin: 10px 0;
      }
      .QRcode_text {
        font-size: 24px;
        font-family: Roboto, Roboto-Bold;
        font-weight: 700;
        text-align: CENTER;
        color: #ffffff;
        line-height: 36px;
      }
    }
  }
  .Product_body {
    width: 100%;
    margin-top: 5%;
    .member {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url("../assets/product/BG 2.png") no-repeat;
      background-size: 100% 40%;
      .member_name {
        font-size: 26px;
        font-family: Open Sans, Open Sans-Bold;
        font-weight: 700;
        text-align: LEFT;
        color: #333333;
        line-height: 26px;
        margin-bottom: 5%;
      }
      .member_content {
        width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .general {
          width: 26%;
          background: #ffffff;
          border: 0.5px solid #f2f6ff;
          border-radius: 25px;
          box-shadow: 0px 15px 45px 0px rgba(0, 0, 0, 0.05);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0 10px;
          .general_name {
            font-size: 20px;
            font-family: Roboto, Roboto-Bold;
            font-weight: 700;
            text-align: LEFT;
            color: #ffac1c;
            line-height: 60px;
          }
          .vip_name {
            font-size: 20px;
            font-family: Roboto, Roboto-Bold;
            font-weight: 700;
            text-align: LEFT;
            color: #1c84ff;
            line-height: 60px;
          }
          .enterprise_name {
            font-size: 20px;
            font-family: Roboto, Roboto-Bold;
            font-weight: 700;
            text-align: LEFT;
            color: #3844ae;
            line-height: 60px;
          }
          .general_money {
            font-size: 40px;
            font-family: Roboto, Roboto-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #333333;
            line-height: 100px;
          }
          .general_text {
            font-size: 14px;
            font-family: Roboto, Roboto-Regular;
            font-weight: 400;
            text-align: CENTER;
            color: #ffac1c;
            line-height: 30px;
          }
          .general_reserve {
            margin: 20px 0;
            border: 1.5px solid #333333;
            border-radius: 5px;
            font-size: 15px;
            font-family: Roboto, Roboto-Regular;
            font-weight: 400;
            color: #000000;
            background: #fff;
          }
          .general_card {
            width: 90%;
          }
          .vip_card {
            width: 90%;
          }
          .enterprise_card {
            width: 90%;
          }
          .general_themes {
            margin: 15px 0;
          }
          .vip_themes {
            margin: 15px 0;
          }
          .enterprise_themes {
            margin: 15px 0;
          }
          ul {
            padding: 0 10%;
            margin-bottom: 20px;
            li {
              line-height: 30px;
              list-style: none;
              i {
                color: #ffac1c;
                margin-right: 10px;
              }
            }
            .color {
              color: #b7b7b7;
              i {
                color: #b7b7b7;
              }
            }
          }
        }
      }
      @media screen and (max-width: 1024px) {
        .member_content {
          flex-direction: column;
          .general {
            width: 90%;
            margin-bottom: 5%;
          }
        }
      }
    }
    .sdk {
      width: 100%;
      padding: 5% 13%;
      background: #fffbf3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .sdk_title {
        font-size: 32px;
        font-family: Open Sans, Open Sans-Bold;
        font-weight: 700;
        text-align: LEFT;
        color: #333333;
        line-height: 42px;
        margin-bottom: 5%;
      }
      .sdk_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .sdk_text {
          padding: 4% 4%;
          font-size: 22px;
          font-family: Roboto, Roboto-Bold;
          font-weight: 600;
          color: #333333;
          line-height: 36px;
          background: #ffffff;
          border: 0.5px solid #f2f6ff;
          border-radius: 10px;
          box-shadow: 0px 25px 50px 0px rgba(22, 25, 79, 0.05);
          margin-bottom: 3%;
        }
      }
      .sdk_down {
        background: #ffac1c;
        border-radius: 5px;
        font-size: 16px;
        font-family: Roboto, Roboto-Regular;
        font-weight: 400;
        color: #ffffff;
        border: none;
      }
    }
  }
}
</style>
